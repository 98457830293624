import React from 'react';
import { AppInputTextFormik } from 'primitives/input/appInputText';

import AppInputPassword from 'primitives/input/appInputPassword';
import { isDev } from 'appEnv';
import * as yup from 'yup';
import { AuthData, CurrentUser } from 'appTypes';
import { getFrontMessage } from '../../../appMessageDefinitions';
import { ShText } from '@shoootin/translations';
import { useAppFormik } from '../../../primitives/input/formikUtils';

import { AppButton } from '../../../primitives/appButton';
import { FormikProvider } from 'formik';
import { FormError } from '../../../primitives/input/formError';

type LoginFormValues = AuthData;

const LoginFormValidation = yup.object().shape<LoginFormValues>({
  email: yup
    .string()
    .trim()
    .email(getFrontMessage('form_userInfos_email_RequiredError').id)
    .required(getFrontMessage('form_userInfos_email_InvalidError').id),

  password: yup
    .string()
    .trim()
    .required(getFrontMessage('form_userInfos_password_RequiredError').id),
});

const debug = true;

export const EmptyLoginForm = {
  email: isDev && debug ? 'jeremy@shooot.in' : '',
  password: isDev && debug ? '00000000' : '',
};

const LoginForm = ({
  initialValues = EmptyLoginForm,
  submitAppForm,
}: {
  initialValues?: LoginFormValues;
  submitAppForm: (values: LoginFormValues) => any;
}) => {
  const { form, globalError } = useAppFormik<LoginFormValues, CurrentUser>({
    validationSchema: LoginFormValidation,
    initialValues,
    submitAppForm,
  });
  return (
    <FormikProvider value={form}>
      <div>
        <div className="form-section half">
          {globalError && <FormError error={globalError} />}
          <div className="field-row">
            <span>
              <AppInputTextFormik
                form={form}
                name="email"
                placeholderMessage={getFrontMessage(
                  'form_userInfos_email_PlaceHolder',
                )}
                modifier="large"
              />
            </span>
          </div>
          <div className="field-row">
            <span>
              <AppInputPassword
                form={form}
                name="password"
                placeholderMessage={getFrontMessage(
                  'form_userInfos_password_PlaceHolder',
                )}
                modifier="large"
                reminder={true}
              />
            </span>
          </div>

          <div className="field-row space">
            <AppButton modifier="large" onClick={() => form.submitForm()}>
              <ShText message={getFrontMessage('common_actions_login')} />
            </AppButton>
          </div>
        </div>
      </div>
    </FormikProvider>
  );
};

export default LoginForm;
