import React from 'react';
import classnames from 'classnames';
const LockSvg = (props: any) => (
  <svg
    height={24}
    width={24}
    viewBox="0 0 24 24"
    fill="none"
    strokeLinecap="round"
    {...props}
    className={classnames(props.className, 'lock')}
  >
    <circle cx={11.5} cy={16} r={2} />
    <path d="M6.5,10.4V5.5c0-2.8,2.2-5,5-5s5,2.2,5,5v4.9" />
    <path d="M8.5,9.1V5.5c0-1.7,1.3-3,3-3s3,1.3,3,3v3.6" />
    <circle cx={11.5} cy={16} r={5.5} />
    <line x1={11.5} y1={11.8} x2={11.5} y2={10.5} />
    <line x1={11.5} y1={21.5} x2={11.5} y2={20.3} />
    <circle cx={11.5} cy={16} r={7.5} />
    <line x1={15.8} y1={16} x2={17} y2={16} />
    <line x1={6} y1={16} x2={7.3} y2={16} />
    <line x1={14.5} y1={13} x2={15.4} y2={12.1} />
    <line x1={7.6} y1={19.9} x2={8.5} y2={19} />
    <line x1={14.5} y1={19} x2={15.4} y2={19.9} />
    <line x1={7.6} y1={12.1} x2={8.5} y2={13} />
  </svg>
);

export default LockSvg;
