import { delayPromise } from '@shoootin/utils';

// Not sure how to type this, as it's supposed to actually quit the page
// See https://stackoverflow.com/questions/55854149/typescript-how-to-handle-typing-when-leaving-a-page
export const redirectBrowser = async (url: string): Promise<never> => {
  console.debug('redirectBrowser', url);
  window.location.href = url;
  await delayPromise(10000);
  throw new Error(`unexpected redirection failure to ${url}`);
};
