import React, { useEffect } from 'react';
import { MainLayout } from '../../layout/mainLayout';

import { H2 } from '../../primitives/appTitle';
import AppSection from '../../primitives/appSection';
import PatternSvg from '../../components/svg/patternSvg';
import './frontLogin.less';
import ChevronPrevSvg from '../../components/svg/picto/chevronPrevSvg';
import LoginForm from './components/loginForm';
import { AppInternalLink, AppInternalPaths } from 'primitives/appLink';
import { useLoginAction } from 'appAuth';
import { useCurrentUserOfAnyType } from '../../state/currentUserState';
import { defineMessages } from 'react-intl';
import { ShText } from '@shoootin/translations';
import { getFrontMessage } from '../../appMessageDefinitions';
import { CurrentUser } from '../../appTypes';
import { redirectBrowser } from '../../appNavigation';
import { userTypeHomeUrl } from 'appEnv';
import { reportError } from '../../appAnalytics';
import { delayPromise } from '@shoootin/utils';
import { ShSpinner } from '@shoootin/components-web';

const prefix = 'front_login';

const messages = defineMessages({
  pageTitle: {
    id: `${prefix}_pageTitle`,
    defaultMessage: 'Login',
  },
});

const HelloAndRedirectSection = ({
  currentUser,
}: {
  currentUser: CurrentUser;
}) => {
  // Automaticaly redirect user to his account after a little delay
  useEffect(() => {
    const doRedirect = async () => {
      await delayPromise(1000);
      await redirectBrowser(userTypeHomeUrl(currentUser.type));
    };
    doRedirect().catch(reportError);
  }, []);

  return (
    <AppSection
      className="login"
      header={
        <H2>
          <span>Bonjour {currentUser.firstName}</span>
          <PatternSvg />
        </H2>
      }
    >
      <ShSpinner />
    </AppSection>
  );
};

const LoginFormSection = () => {
  const loginAction = useLoginAction();
  // TODO redirect when needed
  // const location = useLocation();
  // console.log("LoginFormSection location",location.state);

  return (
    <AppSection
      className="login"
      header={
        <H2>
          <ShText message={messages.pageTitle} tagName="span" />
          <PatternSvg />
        </H2>
      }
    >
      <LoginForm
        submitAppForm={(auth) =>
          loginAction(auth, { redirect: true, userType: undefined })
        }
      />
      <AppInternalLink className="login-link" to={AppInternalPaths.home}>
        <ChevronPrevSvg />{' '}
        <ShText message={getFrontMessage('common_actions_cancel')} />
      </AppInternalLink>
    </AppSection>
  );
};

const LoginPageContent = () => {
  const currentUser = useCurrentUserOfAnyType();
  if (currentUser) {
    return <HelloAndRedirectSection currentUser={currentUser} />;
  } else {
    return <LoginFormSection />;
  }
};

const LoginPage = () => (
  <MainLayout className="page-login light" showContact={false} pageName="login">
    <LoginPageContent />
  </MainLayout>
);

export default LoginPage;
