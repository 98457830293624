import React, { useState } from 'react';
import LockSvg from '../../components/svg/icon/lockSvg';
import { AppInternalLink, AppInternalPaths } from 'primitives/appLink';
import { FormikPropsSlice, getFormikInputBindProps } from './formikUtils';
import { ShIntlMessageDefinition, ShText } from '@shoootin/translations';
import { getFrontMessage } from '../../appMessageDefinitions';
import { AppInputText } from './appInputText';

const AppInputPassword = <FieldName extends string>({
  name,
  form,
  placeholder,
  placeholderMessage,
  modifier,
  icon = 'lock',
  reminder = false,
}: {
  name: FieldName;
  form: FormikPropsSlice<FieldName>;
  placeholder?: string;
  placeholderMessage?: ShIntlMessageDefinition;
  modifier?: string;
  icon?: 'lock';
  reminder?: boolean;
}) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  return (
    <AppInputText
      {...getFormikInputBindProps(form, name)}
      modifier={modifier}
      placeholder={placeholder}
      placeholderMessage={placeholderMessage}
      type={isPasswordVisible ? 'text' : 'password'}
      icon={
        icon === 'lock' && (
          <LockSvg
            onClick={() => setPasswordVisible(!isPasswordVisible)}
            css={{ cursor: 'pointer' }}
          />
        )
      }
      fieldControlChildren={
        reminder && (
          <div className="field-control__reminder">
            <AppInternalLink
              className="link"
              to={AppInternalPaths.lostPassword}
            >
              <ShText
                message={getFrontMessage('common_actions_forgottenPassword')}
              />
            </AppInternalLink>
          </div>
        )
      }
    />
  );
};

export default AppInputPassword;
