import { authenticate, logout } from './appAPI';
import { AuthData, CurrentUser, UserType } from './appTypes';
import { CurrentUserStorage } from './appStorage';
import { useSetCurrentUser } from './state/currentUserState';
import { useCallback } from 'react';
import { redirectBrowser } from './appNavigation';
import { userTypeHomeUrl } from './appEnv';
import { ShApiClient } from '@shoootin/api';
import { delayPromise } from '@shoootin/utils';

export const redirectCurrentUserAfterAuth = async (user: CurrentUser) => {
  // Delay is important here, if we redirect too fast localstorage may not be set!
  await delayPromise(300);
  await redirectBrowser(userTypeHomeUrl(user.type));
};

export const useLoginAction = () => {
  const setCurrentUser = useSetCurrentUser();
  return useCallback(
    async (
      params: AuthData,
      config: {
        // Weither or not to redirect the user to the legacy BO after a successful login
        redirect: boolean;
        // Permit restrict valid credentials to a specific userType
        userType: UserType | undefined;
      },
    ) => {
      const { user } = await authenticate(params, config.userType);
      CurrentUserStorage.set(user);
      if (config.redirect) {
        await redirectCurrentUserAfterAuth(user);
      }
      // should not be executed in case of a redirect
      setCurrentUser(user);
    },
    [setCurrentUser],
  );
};
export type LoginAction = ReturnType<typeof useLoginAction>;

export const useLogoutAction = () => {
  const setCurrentUser = useSetCurrentUser();
  return useCallback(async () => {
    CurrentUserStorage.set(null);
    try {
      await logout();
    } catch (e) {
      // we don't want to block logout actions in case of network problem
      console.error('unable to logout from API');
    }
    setCurrentUser(null);
    // Auth token is set for the order checkout process and must be cleaned
    // This must be done last, because the logout api call must be done
    // with this auth token to handle the checkout user deassociation
    ShApiClient.setAuthToken(undefined);
  }, [setCurrentUser]);
};
export type LogoutAction = ReturnType<typeof useLogoutAction>;
